<template>
  <div class="tutoriels">
    <PageContent v-if="loaded">
      <template #header-right>
        <ButtonClassic
          balise-type="router-link"
          label="Retour"
          variant="ghost"
          color="primary"
          icon="right"
          :router-link-obj="{ name: 'home', }"
        >
          <template #right-icon>
            <UilArrowRight />
          </template>
        </ButtonClassic>
      </template>

      <template #content>
        <section class="grid-zone">
          <figure
            v-for="video in videos"
            :key="video.key"
          >
            <video
              controls
              :poster="require('@lde/core_lde_vue/dist/assets/media/logos/logo_lde.svg')"
            >
              <source
                :src="require(`@/assets/media/videos/tutoriels/${video.key}.mp4`)"
                type="video/mp4"
              />
              {{ $t("info.desole-navigateur-ne-supporte-pas-video") }}
            </video>
            <figcaption class="s6 bold text-center">
              {{ video.name }}
            </figcaption>
          </figure>
        </section>
      </template>
    </PageContent>

    <div
      v-else
      id="app_loader"
    >
      <InfiniteLoader
        :height="64"
        :width="64"
      />
    </div>
  </div>
</template>

<script>
import { PageContent, InfiniteLoader, ButtonClassic } from "@lde/core_lde_vue";

import { mapGetters } from "vuex";

import { UilArrowRight } from "@iconscout/vue-unicons";

/**
 * Affiche la page des tutoriels.
 */
export default {
  name: "Tutoriels",
  components: {
    PageContent,
    InfiniteLoader,
    ButtonClassic,
    UilArrowRight,
  },
  data() {
    return {
      loaded: false,
      videos: [
        {
          key: "acces_catalogue_profils_connexion",
          name: this.$t("tutoriel.acces-catalogue-profil-connexion"),
        },
        {

          key: "creation_devis",
          name: this.$t("tutoriel.creation-devis"),
        },
        {
          key: "creation_listes",
          name: this.$t("tutoriel.creation-listes"),
        },
        {
          key: "fiche_article",
          name: this.$t("tutoriel.fiche-article"),
        },
        {
          key: "gestion_devis_prescripteur_proposition",
          name: this.$t("tutoriel.gestion-devis-prescripteur-proposition"),
        },
        {
          key: "gestion_devis_valideur",
          name: this.$t("tutoriel.gestion-devis-valideur"),
        },
        {
          key: "recherche_catalogue",
          name: this.$t("tutoriel.recherche-catalogue"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isLogged"]),
  },
  mounted() {
    this.$store.dispatch("login", { noRedirect: true }).then(() => {
      if (this.isLogged) {
        this.$router.push({ name: "403" });
      } else {
        this.loaded = true;
      }
    });
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

.tutoriels {
  figure { grid-column: span 3; }

  video {
    margin-bottom: var.$space-x-tiny;
    width: 100%;
  }

  figcaption { color: var.$blue; }

  #app_loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: var.$white;
  }
}
</style>
